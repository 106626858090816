<template>
  <article class="Gracias">
    <div class="container page-404__container">
      <img
        class="is-img-dr"
        :src="require('@/assets/capacitacion/gracias.png')"
        style="height:auto;max-height:400px;width: auto;"
      />
      <div class="page-404__title">¡Gracias por inscribirte!</div>
      <div class="page-404__subtitle">En la brevedad posible nuestro personal del area de capacitación se comunicará contigo para darte fechas y grupos próximos</div><br>
      <br>
      <router-link
        class="th-hero-slider__btn btn btn--technical"
        to="/"
      >Regresar al inicio</router-link>
      <!-- <ul class="type-service__list row">
        <li class="type-service__item col-12 col-md">
          <div class="type-service__item-icon type-service__item-icon--interior">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="48"
              width="48"
            >
              <path d="M24 46q-5.6 0-10.8-3.3Q8 39.4 5 35.15v5.35q0 .65-.425 1.075Q4.15 42 3.5 42q-.65 0-1.075-.425Q2 41.15 2 40.5v-9q0-.65.425-1.075Q2.85 30 3.5 30h9q.65 0 1.075.425Q14 30.85 14 31.5q0 .65-.425 1.075Q13.15 33 12.5 33H7.15q2.55 3.85 7.275 6.925Q19.15 43 24 43q7.5 0 12.95-5.075 5.45-5.075 6-12.425.05-.65.475-1.075Q43.85 24 44.5 24q.65 0 1.075.45.425.45.375 1.05-.3 4.3-2.125 8.025-1.825 3.725-4.775 6.5T32.225 44.4Q28.35 46 24 46ZM3.5 24q-.65 0-1.075-.45Q2 23.1 2.05 22.5q.3-4.3 2.125-8.025Q6 10.75 8.95 7.975T15.775 3.6Q19.65 2 24 2q5.6 0 10.8 3.3Q40 8.6 43 12.85V7.5q0-.65.425-1.075Q43.85 6 44.5 6q.65 0 1.075.425Q46 6.85 46 7.5v9q0 .65-.425 1.075Q45.15 18 44.5 18h-9q-.65 0-1.075-.425Q34 17.15 34 16.5q0-.65.425-1.075Q34.85 15 35.5 15h5.35q-2.55-3.85-7.25-6.925Q28.9 5 24 5q-7.5 0-12.95 5.075-5.45 5.075-6 12.425-.05.65-.475 1.075Q4.15 24 3.5 24Zm20.45 14.35q-.55 0-.975-.4-.425-.4-.425-.95v-1.35q-1.9-.5-3.275-1.525T16.9 31.45q-.35-.55-.125-1.075.225-.525.875-.775.45-.15.875.075.425.225.675.625.75 1.45 2.125 2.25t3.025.8q1.95 0 3.3-1.125Q29 31.1 29 29.45q0-1.6-1.15-2.6t-4.7-2.35q-3.45-1.3-4.725-2.75-1.275-1.45-1.275-3.9 0-2.25 1.475-3.75t4.025-1.85v-1.2q0-.55.425-.95.425-.4.975-.4t.95.4q.4.4.4.95v1.2q1.55.15 2.775.85 1.225.7 2.075 1.9.35.45.175 1t-.725.8q-.45.25-.95.1-.5-.15-.85-.55-.7-.9-1.65-1.35-.95-.45-2.15-.45-1.95 0-3.075.875Q19.9 16.3 19.9 17.85q0 1.65 1.175 2.675T25.4 22.6q3.5 1.15 4.925 2.7 1.425 1.55 1.425 4.15 0 2.6-1.725 4.275Q28.3 35.4 25.3 35.75v1.2q0 .55-.4.975-.4.425-.95.425Z" />
            </svg>
          </div>
          <h3 class="type-service__item-heading">Transferencia</h3>
          <div class="type-service__item-text">
            <strong>Banco:</strong> BBVA Bancomer<br>
            <strong>Cuenta:</strong> 0481 5021 07<br>
            <strong>CLABE:</strong> 0121 5000 4815 0210 79<br><br>
            <a
              href="https://api.whatsapp.com/send?phone=5216141901208"
              target="_blank"
            >Recuerda enviarnos el comprobante de pago al <strong>614 190 1208</strong></a>
          </div>
          <a
            class="mt24 th-hero-slider__btn btn btn--technical"
            href="https://api.whatsapp.com/send?phone=5216141901208"
            target="_blank"
          >Enviar comprobante</a>
        </li>
        <li class="type-service__item col-12 col-md-1" />
        <li class="type-service__item col-12 col-md">
          <div class="type-service__item-icon type-service__item-icon--interior">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="48"
              width="48"
            >
              <path d="M24 34.05q.95 0 1.6-.65.65-.65.65-1.6v-5.55h5.55q.95 0 1.6-.65.65-.65.65-1.6 0-.95-.65-1.6-.65-.65-1.6-.65h-5.55V16.2q0-.95-.65-1.6-.65-.65-1.6-.65-.95 0-1.6.65-.65.65-.65 1.6v5.55H16.2q-.95 0-1.6.65-.65.65-.65 1.6 0 .95.65 1.6.65.65 1.6.65h5.55v5.55q0 .95.65 1.6.65.65 1.6.65ZM9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h30q1.2 0 2.1.9.9.9.9 2.1v30q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V9H9v30Zm0 0V9v30Z" />
            </svg>
          </div>
          <h3 class="type-service__item-heading">Unidad médica</h3>
          <div class="type-service__item-text">También puedes acudir a cualquierea de nuestras unidades médica a realizar el pago correspondiente del curso.</div>
          <router-link
            class="mt24 th-hero-slider__btn btn btn--technical"
            to="/unidades-medicas"
          >Ver unidades médicas</router-link>
        </li>
      </ul> -->
      <br><br>
    </div>
  </article>
</template>
<script>
export default {
  name: "Gracias",
};
</script>